// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css'; // Optional: global styles

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="login.whatthephish.ai"
    clientId="pfMI5SkX9suOgWU99rE9msa5NB88mjhN"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/ReportsList`,
    }}
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>
);
