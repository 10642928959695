import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { Button, Switch } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import logo from './logo.png';

const HeaderContainer = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #282c34;
  color: white;
`;

const Header = ({ darkMode, setDarkMode }) => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <HeaderContainer>
      <Link to="/">
      <img src={logo} alt="What The Phish Reports" style={{ width: '50%', height: 'auto' }}  />
      </Link>
      <nav>
        <Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
        {isAuthenticated ? (
          <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
        ) : (
          <Button onClick={loginWithRedirect}>Login</Button>
        )}
      </nav>
    </HeaderContainer>
  );
};

export default Header;
