import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import LoginButton from './LoginButton';

const Home = () => {
  return (
    <Container maxWidth="md" sx={{ paddingTop: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Welcome to What The Phish
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Your Ultimate Solution for Email Security and Analysis
      </Typography>
      <Typography variant="body1" paragraph>
        What The Phish helps protect your organization from phishing attacks and other email threats by analyzing emails for suspicious links, content, and sender information. Our advanced algorithms and intuitive interface make it easy to identify potential risks and take action promptly.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Features
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            <strong>Link Analysis:</strong> Detect and evaluate suspicious links within emails to prevent phishing attempts.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Content Inspection:</strong> Identify phishing words and phrases commonly used in fraudulent emails.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Sender Verification:</strong> Analyze sender information to detect spoofed or compromised accounts.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Email Search:</strong> Quickly search and retrieve emails based on various criteria from your Cloudflare D1 database.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>User Authentication:</strong> Secure access to the app with Auth0 integration.
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" component="h3" gutterBottom>
        Benefits
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            <strong>Enhanced Security:</strong> Protect your organization from email-based threats.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Ease of Use:</strong> Intuitive interface that requires minimal training.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Real-Time Analysis:</strong> Out Outlook add-on provides immediate feedback on potential email risks.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Scalable:</strong> Suitable for businesses of all sizes.
          </Typography>
        </li>
      </ul>
      <Box sx={{ marginTop: 4 }}>
        <LoginButton />
      </Box>
    </Container>
  );
};

export default Home;
