// src/App.js
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Header from './components/Header';
import ReportList from './components/ReportList';
import ReportDetails from './components/ReportDetails';
import Home from './components/Home';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
  const { user, isAuthenticated } = useAuth0();
  const [reports, setReports] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const isAdmin = user?.['https://example.com/roles']?.includes('Admin');

  // Toggle body class for theme
  useEffect(() => {
    document.body.className = darkMode ? 'dark-theme' : 'light-theme';
  }, [darkMode]);

  // Mock fetch function for reports
  const fetchReportById = async (id) => {
    // Replace this with an actual API call or a function that fetches report by ID
    return reports.find((report) => report.id === id);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const fetchReports = async () => {
        try {
          const response = await fetch('/api/reports');
          const data = await response.json();
          setReports(data);
        } catch (error) {
          console.error('Error fetching reports:', error);
        }
      };
      fetchReports();
    }
  }, [isAuthenticated]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <Routes>
        <Route path="/" element={<Home />} />
          <Route
            path="/reports"
            element={withAuthenticationRequired(() => (
              <ReportList reports={reports} />
            ))}
          />
        <Route
          path="/report/:id"
          element={withAuthenticationRequired(() => (
            <ReportDetails fetchReportById={fetchReportById} />
          ))}
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
