// src/components/ReportDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Button, Snackbar, Alert } from '@mui/material';
import jsPDF from 'jspdf';
import { CSVLink } from 'react-csv';

const ReportDetails = ({ fetchReportById }) => {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        if (fetchReportById) {
          const fetchedReport = await fetchReportById(id);
          if (!fetchedReport) throw new Error("Report not found");
          setReport(fetchedReport);
          await generateReportSummary(fetchedReport);
        } else {
          throw new Error("fetchReportById function is missing");
        }
      } catch (error) {
        console.error('Error fetching report:', error);
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    fetchReport();
  }, [id]);

  const generateReportSummary = async (report) => {
    try {
      const response = await fetch('/api/generateSummary', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content: report })
      });
      if (response.ok) {
        const data = await response.json();
        setSummary(data.summary);
      } else {
        console.error('Error generating report summary');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error generating report summary:', error);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text(`Report ID: ${report.id}`, 10, 10);
    doc.text(`Classification: ${report.classification}`, 10, 20);
    doc.text(`Summary: ${summary}`, 10, 30);
    report.parsedUrlFeatures.forEach((urlFeature, index) => {
      doc.text(`Link: ${urlFeature.link}`, 10, 40 + index * 10);
    });
    doc.save(`Report_${report.id}.pdf`);
  };

  const csvData = report
    ? report.parsedUrlFeatures.map((feature) => ({
        id: report.id,
        classification: report.classification,
        summary,
        link: feature.link,
        ...feature.features,
      }))
    : [];

  if (loading) return <CircularProgress />;

  return (
    <div>
      <Typography variant="h4">Report ID: {report.id}</Typography>
      <Typography variant="body1"><strong>Classification:</strong> {report.classification}</Typography>
      <Typography variant="h5">Summary:</Typography>
      <Typography variant="body1">{summary}</Typography>
      <Button variant="contained" color="primary" onClick={exportToPDF}>Export as PDF</Button>
      <Button variant="contained" color="secondary" style={{ marginLeft: '10px' }}>
        <CSVLink data={csvData} filename={`Report_${report.id}.csv`} style={{ color: 'white', textDecoration: 'none' }}>
          Export as CSV
        </CSVLink>
      </Button>

      {/* Error Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          Error loading report data. Please try again later.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReportDetails;
